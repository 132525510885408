import Column from '../../components/ConnectedTable/Column';
import Task from '../../models/Task';
import moment from 'moment';
import Deadline from '../../components/Deadline';
import { Tooltip, Typography } from '@mui/material';
import Status from './customCells/Status';
import getShortLanguageName from '../../utils/getShortLanguageName';
import { UserRole } from '../../enums/UserRole';
import TaskActions from './customCells/TaskActions';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

const getColumns = (role?: UserRole): Column<Task>[] => {
    if (!role) {
        return [];
    }

    const columns: Column<Task>[] = [
        {
            id: 'label',
            label: '',
            width: 30,
            getter(task) {
                return <>
                    {task.label &&
                        <Tooltip title={task.label}>
                            <Icon fontSize={16} icon={'ant-design:folder-open-outlined'} />
                        </Tooltip>
                    }
                    {role === UserRole.ADMIN && task.directedToAdmin && (
                        <Tooltip title={'Direct to admin'}>
                            <Icon fontSize={16} icon={'ri:admin-fill'} />
                        </Tooltip>
                    )}
                </>;
            },
        },
        {
            id: 'taskId', label: 'Task ID', isClickable: true, width: 90,
            getter(task) {
                return <Link to={'/tasks/' + task.id + '/edit'}>#{task.taskId}</Link>;
            },
        },
        {
            width: 100,
            id: 'created',
            label: 'Created',
            getter(task) {
                const date = moment(task.created).format('DD/MM/YY HH:mm');
                return <Typography fontSize={12} noWrap>{date}</Typography>;
            },
            isClickable: true,
        },
        {
            id: 'translationStartedAt',
            label: 'Started',
            getter(task) {
                if (!task.translationStartedAt) {
                    return '-';
                }
                const date = moment(task.translationStartedAt).format('DD/MM/YY HH:mm');
                return <Typography fontSize={12} noWrap>{date}</Typography>;
            },
        },
        {
            id: 'files', label: 'Files', getter(task) {
                const files = task.files?.[0]?.filename ?? 'No original file uploaded';
                return <Tooltip title={files}><Typography fontSize={12} noWrap>{files}</Typography></Tooltip>;
            },
        },
        {
            id: 'deadLine',
            label: 'Deadline',
            width: 120,
            getter(task) {
                return <Deadline task={task} />;
            },
        },
        {
            id: 'status',
            label: 'Status',
            getter(task) {
                return <Status task={task} role={role} />;
            },
            width: 120,
            isClickable: true,
        },
        {
            id: 'approvedAt',
            label: '',
            width: 170,
            align: 'right',
            getter(task) {
                return <TaskActions task={task} role={role} />;
            },
        },
    ];

    return columns;
};


export default getColumns;
