import { Box, Card, IconButton, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import SelectRemoteOptions from '../../../components/Form/fields/SelectRemoteOptions';
import SwitcherFieldRevert from '../../../components/Form/fields/SwitcherFieldRevert';
import Task from '../../../models/Task';
import TaskLanguage from '../../../models/TaskLanguage';

const TranslationTask: React.FC = () => {
    const { values: task, setFieldValue } = useFormikContext<Task>();

    const onAddLanguage = (type: 'sourceLanguages' | 'targetLanguages') => {
        const newLanguage: TaskLanguage = {
            language: undefined, languageId: '',

        };
        if (type === 'sourceLanguages') {
            setFieldValue('sourceLanguages', task.sourceLanguages ? [...task.sourceLanguages, newLanguage] : [newLanguage]);
        } else {
            setFieldValue('targetLanguages', task.targetLanguages ? [...task.targetLanguages, newLanguage] : [newLanguage]);
        }
    };

    function removeLanguageByIndex(langIndex: number, type: 'sourceLanguages' | 'targetLanguages') {
        if (type === 'sourceLanguages') {
            const langArray = task.sourceLanguages ? [...task.sourceLanguages] : [];
            setFieldValue('sourceLanguages', langArray.filter((_, index) => index !== langIndex));
        } else {
            const langArray = task.sourceLanguages ? [...task.sourceLanguages] : [];
            setFieldValue('targetLanguages', langArray.filter((_, index) => index !== langIndex));
        }
    }

    return (
        <Card sx={{ p: 2 }}>
            <Stack spacing={2}>
                <Typography variant='subtitle2' gutterBottom>
                    Language Settings
                </Typography>
                <Box sx={{ m: 1 }}>
                    <Stack>
                        <Typography variant='subtitle2'>From:</Typography>
                        {task.sourceLanguages?.map((language, index) => (
                            <Stack direction={'row'} spacing={2} key={language.id} sx={{ my: 1 }}>
                                <SelectRemoteOptions label={'From'} name={`sourceLanguages[${index}].id`} queryKey={'languages'} />
                                <IconButton onClick={() => removeLanguageByIndex(index, 'sourceLanguages')}>
                                    <Icon icon={trash2Outline} width={20} height={20} />
                                </IconButton>
                            </Stack>
                        ))}
                        <Stack direction='column' gap={2} alignItems="center">
                            <IconButton color="inherit" onClick={() => onAddLanguage('sourceLanguages')}>
                                <Icon icon={plusFill} width={20} height={20} />
                            </IconButton>
                        </Stack>
                        <Typography variant='subtitle2'>To:</Typography>
                        {task.targetLanguages?.map((language, index) => (
                            <Stack direction={'row'} spacing={2} key={language.id} sx={{ my: 1 }}>
                                <SelectRemoteOptions label={'To'} name={`targetLanguages[${index}].id`} queryKey={'languages'} />
                                <IconButton onClick={() => removeLanguageByIndex(index, 'targetLanguages')}>
                                    <Icon icon={trash2Outline} width={20} height={20} />
                                </IconButton>
                            </Stack>
                        ))}
                        <Stack direction='column' alignItems="center">
                            <IconButton color="inherit" onClick={() => onAddLanguage('targetLanguages')}>
                                <Icon icon={plusFill} width={20} height={20} />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <Box sx={{ m: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <SwitcherFieldRevert label={'Free task'} name={'include'} />
                    </Box>
                </Box>
            </Stack>
        </Card>
    );
};

export default TranslationTask;
