/* eslint-disable indent */
import {
    Box, CardActionArea, Drawer,
    Stack, Tooltip,
    useMediaQuery,
} from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { MHidden } from '../../components/@material-extend';
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
import { UserRole } from '../../enums/UserRole';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import User from '../../models/User';
import getSidebarConfig from './SidebarConfig';
import SidebarLogs from './SidebarLogs/SidebarLogs';
import useUserEventLogs from './SidebarLogs/useUserEventLogs';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.complex,
        }),
    },
}));

type IconCollapseProps = {
    onToggleCollapse: VoidFunction;
    collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
    return (
        <Tooltip title="Minimize Sidebar">
            <CardActionArea
                onClick={onToggleCollapse}
                sx={{
                    width: 18,
                    height: 18,
                    display: 'flex',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    alignItems: 'center',
                    color: 'text.primary',
                    justifyContent: 'center',
                    border: 'solid 1px currentColor',
                    ...(collapseClick && {
                        borderWidth: 2,
                    }),
                }}
            >
                <Box
                    sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        bgcolor: 'currentColor',
                        transition: (theme) => theme.transitions.create('all'),
                        ...(collapseClick && {
                            width: 0,
                            height: 0,
                        }),
                    }}
                />
            </CardActionArea>
        </Tooltip>
    );
}

type SidebarProps = {
    isOpenSidebar: boolean;
    onCloseSidebar: VoidFunction;
    user: User;
};

export default function Sidebar({ user, isOpenSidebar, onCloseSidebar }: SidebarProps): JSX.Element {
    const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
        useCollapseDrawer();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { data: logs, isLoading: isLogsLoading } = useUserEventLogs(isCollapse);

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, []);

    const renderContent = (
        <Scrollbar
            sx={{
                'height': '100%',
                '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' },
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    px: 2.5,
                    pt: 3,
                    pb: 2,
                    ...(isCollapse && {
                        alignItems: 'center',
                    }),
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Box sx={{ display: 'inline-flex' }}>
                        <Logo redirectTo='/' />
                    </Box>
                    <MHidden width="lgDown">
                        {!isCollapse && (
                            <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
                        )}
                    </MHidden>
                </Stack>
            </Stack>

            {user && (user.role === UserRole.ADMIN || user.role === UserRole.AGENT) && !isMobile ? (
                <Box flexGrow={1} />
            ) : (
                <NavSection navConfig={getSidebarConfig()} isShow={!isCollapse} />
            )}

            {user && (user.role === UserRole.ADMIN || user.role === UserRole.AGENT) &&
                !isCollapse && !isMobile && (
                    <SidebarLogs data={logs} isLoading={isLogsLoading} />
                )}

        </Scrollbar>
    );

    return (
        <RootStyle
            sx={{
                width: {
                    lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
                },
                ...(collapseClick && {
                    position: 'absolute',
                }),
            }}
        >
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>

            <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    onMouseEnter={onHoverEnter}
                    onMouseLeave={onHoverLeave}
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default',
                            ...(isCollapse && {
                                width: COLLAPSE_WIDTH,
                            }),
                            ...(collapseHover && {
                                borderRight: 0,
                                backdropFilter: 'blur(6px)',
                                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                                boxShadow: (theme) => theme.customShadows.z20,
                                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88),
                            }),
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
}
