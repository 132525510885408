import React, { useEffect } from 'react';
import { Card, Container, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router';
import useTask from '../useTask';
import Form from '../../../components/Form';
import Page from '../../../components/Page';
import TaskDetailEditor from '../TaskDetailEditor';
import Task from '../../../models/Task';
import { LoadingButton } from '@mui/lab';
import useUpdateTask from '../useUpdateTask';
import TextField from '../../../components/Form/fields/TextField';
import FileByType from '../FilesByType';
import { FileType } from '../../../enums/FileType';
import Modifications from '../Modifications';
import CommentsSection from '../CommentsSection';
import TaskLoading from '../TaskLoading';
import useSeenTask from '../useSeenTask';
import StatusHandler from '../StatusHandler/StatusHandler';
import EditTaskSchema from './EditTaskSchema';
import TaskInfo from '../TaskInfo';


const AdminEditor: React.FC = () => {
  const { id: taskId } = useParams();
  const { data: task } = useTask(taskId);
  const { mutate: updateTask } = useUpdateTask(taskId);
  const { mutate: setSeen } = useSeenTask();

  useEffect(() => {
    setSeen({ id: taskId });
  }, [setSeen, taskId]);

  if (!task) {
    return <TaskLoading />;
  }

  function onKeyDown(keyEvent: React.KeyboardEvent<HTMLFormElement>) {
    if (keyEvent.key === 'Enter' || keyEvent.key === '13') {
      keyEvent.preventDefault();
    }
  }

  return (
    <Page title="YLT" >
      <Form<Task>
        validationSchema={EditTaskSchema} onSubmit={updateTask} initialValues={task} onKeyDown={onKeyDown}>
        <Container maxWidth={'lg'}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Stack gap={2}>
                <Card sx={{ p: 2 }}>
                  <TaskInfo task={task} />
                </Card>
                <Card sx={{ p: 2 }}>
                  <Stack gap={2}>
                    <Typography variant='subtitle2' color='text.secondary' gutterBottom>
                      Task ID
                    </Typography>
                    <TextField
                      name={'taskId'}
                      label={'ID'}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">#</InputAdornment>,
                      }} />
                  </Stack>
                </Card>
                <Card sx={{ p: 2 }}>
                  <Stack gap={2}>
                    <Typography variant='subtitle2' color='text.secondary' gutterBottom>
                      Company
                    </Typography>
                    <TextField name={'company'} label={'Company'} />
                  </Stack>
                </Card>
                <Card sx={{ p: 2 }}>
                  <Stack gap={2}>
                    <Typography variant='subtitle2' color='text.secondary' gutterBottom>
                      Details
                    </Typography>
                    <TaskDetailEditor showTranslationOptions />
                  </Stack>
                </Card>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack gap={2}>
                <FileByType
                  fileType={FileType.TRANSLATED}
                  taskId={taskId}
                  bgColor={'background.neutral'} />
                <FileByType
                  fileType={FileType.ORIGINAL}
                  taskId={taskId}
                  parseStatus={task.originalFileParseStatus}
                  charCount={task.originalFileCharacterCount}
                  bgColor={'background.neutral'} />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack gap={2}>
                <StatusHandler task={task} />
                <LoadingButton
                  fullWidth
                  variant="contained"
                  size="small"
                  color="secondary"
                  type={'submit'}>
                  Save task
                </LoadingButton>

                <CommentsSection />

                <Modifications taskId={taskId} />

              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Form>
    </Page>
  );
};

export default AdminEditor;
