/* eslint-disable no-unused-vars */
export enum UserRole {
    ADMIN = 'admin',
    USER = 'user',
    TRANSLATOR = 'translator',
    AGENT = 'agent',
}

export function isAdmin(role: UserRole): boolean {
    return UserRole.ADMIN === role;
}

export const roles: UserRole[] = [UserRole.ADMIN, UserRole.TRANSLATOR, UserRole.USER, UserRole.AGENT];
