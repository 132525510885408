import { Card, Stack, Typography } from '@mui/material';
import DatePicker from '../../../components/Form/fields/DatePicker';

const Options: React.FC = () => {
    return (
        <Card sx={{ p: 2 }}>
            <Stack spacing={2}>
                <Typography variant='subtitle2' gutterBottom>
                    Translation Settings
                </Typography>
                <Stack sx={{ m: 1 }} gap={2}>
                    <DatePicker name={'deadLine'} label={'Deadline'} />
                </Stack>
            </Stack>
        </Card>
    );
};

export default Options;
