/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable new-cap */
import React, { createContext, ReactNode } from 'react';
// @type
import { SettingsContextProps, ThemeColor, ThemeDirection, ThemeMode } from '../@types/settings';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
// theme
import palette from '../theme/palette';

// ----------------------------------------------------------------------

const PRIMARY_COLOR = [
    // BLUE
    {
        name: 'default',
        ...palette.light.primary,
    },
    // PURPLE
    {
        name: 'purple',
        lighter: '#EBD6FD',
        light: '#B985F4',
        main: '#7635dc',
        dark: '#431A9E',
        darker: '#200A69',
        contrastText: '#fff',
    },
    // BLUE
    {
        name: 'blue',
        lighter: '#CCDFFF',
        light: '#7BB6E7',
        main: '#4695D6',
        dark: '#357CB7',
        darker: '#1D5888',
        contrastText: '#fff',
    },
    // RED
    {
        name: 'red',
        lighter: '#FFE3D5',
        light: '#FFC1AC',
        main: '#FF3030',
        dark: '#B71833',
        darker: '#7A0930',
        contrastText: '#fff',
    },
    // ORANGE
    {
        name: 'orange',
        lighter: '#FEF4D4',
        light: '#FED680',
        main: '#f19200',
        dark: '#B66816',
        darker: '#793908',
        contrastText: '#fff',
    },

];

function SetColor(themeColor: ThemeColor) {
    let color;
    const DEFAULT = PRIMARY_COLOR[0];
    const PURPLE = PRIMARY_COLOR[1];
    const BLUE = PRIMARY_COLOR[2];
    const RED = PRIMARY_COLOR[3];
    const ORANGE = PRIMARY_COLOR[4];

    switch (themeColor) {
        case 'purple':
            color = PURPLE;
            break;
        case 'blue':
            color = BLUE;
            break;
        case 'red':
            color = RED;
            break;
        case 'orange':
            color = ORANGE;
            break;
        default:
            color = ORANGE;
    }
    return color;
}

const initialState: SettingsContextProps = {
    themeMode: 'light',
    themeDirection: 'ltr',
    themeColor: 'default',
    tableExtend: false,
    onChangeMode: () => { },
    onChangeDirection: () => { },
    onChangeColor: () => { },
    onChangeTableExtend: () => { },
    setColor: PRIMARY_COLOR[0],
    colorOption: [],
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
    children: ReactNode;
};

function SettingsProvider({ children }: SettingsProviderProps): JSX.Element {
    const [settings, setSettings] = useLocalStorage('settings', {
        themeMode: initialState.themeMode,
        themeDirection: initialState.themeDirection,
        themeColor: initialState.themeColor,
        tableExtend: initialState.tableExtend,
    });

    const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettings({
            ...settings,
            themeMode: (event.target as HTMLInputElement).value as ThemeMode,
        });
    };

    const onChangeDirection = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettings({
            ...settings,
            themeDirection: (event.target as HTMLInputElement).value as ThemeDirection,
        });
    };

    const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSettings({
            ...settings,
            themeColor: (event.target as HTMLInputElement).value as ThemeColor,
        });
    };

    const onChangeTableExtend = () => {
        setSettings({
            ...settings,
            tableExtend: !settings.tableExtend,
        });
    };

    return (
        <SettingsContext.Provider
            value={{
                ...settings,
                // Mode
                onChangeMode,
                // Direction
                onChangeDirection,
                // Color
                onChangeColor,
                setColor: SetColor(settings.themeColor),
                colorOption: PRIMARY_COLOR.map((color) => ({
                    name: color.name,
                    value: color.main,
                })),
                // Table Extend
                onChangeTableExtend,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
}

export { SettingsProvider, SettingsContext };
