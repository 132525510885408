/* eslint-disable no-unused-vars */
export enum UserRole {
    ADMIN = 'admin',
    CUSTOMER = 'customer',
    TRANSLATOR = 'translator',
}

export function isAdmin(role: UserRole): boolean {
    return UserRole.ADMIN === role;
}
