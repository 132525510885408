import React, { useState } from 'react';
import { Card, Container, Grid, Stack, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Form from '../../../components/Form';
import Page from '../../../components/Page';
import Task from '../../../models/Task';
import { FileType } from '../../../enums/FileType';
import { LoadingButton } from '@mui/lab';
import TextField from '../../../components/Form/fields/TextField';
import TranslationTask from '../TranslationTask';
import Options from '../Options';
import CustomFile from '../../../interfaces/CustomFile';
import FileToTranslate from '../FileToTranslate';
import useCreateTask from '../useCreateTask';
import NewTaskSchema from '../NewTaskSchema';
import TaskLoading from '../../TaskEditor/TaskLoading';
import { useSnackbar } from 'notistack';
import useMe from '../../../hooks/useMe';


const CustomerNewTask: React.FC = () => {
    const { data: me } = useMe();
    const { enqueueSnackbar } = useSnackbar();
    const [files, setFiles] = useState<CustomFile[]>([]);
    const { mutate: createTask, isLoading } = useCreateTask(files);

    const initialTask = new Task();
    initialTask.customerId = me?.customerId;

    if (isLoading) {
        return <TaskLoading />;
    }

    const onUpdateFiles = (files: File[]) => {
        const extendedFiles: CustomFile[] = files.map((file) => {
            return { id: uuidv4(), file: file, type: FileType.ORIGINAL };
        });
        setFiles((oldFiles) => oldFiles.concat(extendedFiles));
    };

    const onSubmit = (values: Task) => {
        if (files.length < 1) {
            enqueueSnackbar('At least one file must be selected', { variant: 'error' });
            return false;
        }
        if (!values.sourceLanguages?.length) {
            enqueueSnackbar('Source Language required!', { variant: 'error' });
        } else if (!values.targetLanguages?.length) {
            enqueueSnackbar('Target Language required!', { variant: 'error' });
        } else {
            createTask(values);
        }
    };

    return (
        <Page title="New Task | YLT" >
            <Form<Task>
                validationSchema={NewTaskSchema} onSubmit={onSubmit} initialValues={initialTask}>
                <Container maxWidth={'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <TranslationTask />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <Options />
                                <FileToTranslate
                                    files={files}
                                    setFiles={setFiles}
                                    onUpload={
                                        (files) => {
                                            onUpdateFiles(files);
                                        }
                                    }
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <Card sx={{ p: 2 }}>
                                    <Stack gap={2}>
                                        <Typography variant='subtitle2' gutterBottom>
                                            Leave note
                                        </Typography>
                                        <TextField name={'note'} label={'Note about the task...'} />
                                    </Stack>
                                </Card>
                                <Card sx={{ p: 2 }}>
                                    <Stack gap={2}>
                                        <Typography variant='subtitle2' gutterBottom>
                                            Company
                                        </Typography>
                                        <TextField name={'company'} label={'Set Company'} />
                                    </Stack>
                                </Card>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                >
                                    Save
                                </LoadingButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Form>
        </Page>
    );
};

export default CustomerNewTask;
