import React from 'react';
import Task from '../../../models/Task';
import { Stack, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import Customer from '../../../models/Customer';
import useCustomers from '../../../hooks/useCustomers';

const TaskInfo: React.FC<{ task: Task }> = ({ task }) => {
  const { data: customers } = useCustomers();
  const { data: customer } = useQuery<Customer>(['customers/', task.customerId]);

  const companyName = customers?.find((c) => c.id === customer?.id)?.name;

  return <>
    <Stack direction={'row'} gap={2}>
      <Typography variant='subtitle2' color='text.secondary'>Customer:</Typography>
      <Typography variant='subtitle2' sx={{ maxWidth: 100, maxHeight: 20, overflow: 'hidden' }}>{companyName}</Typography>
    </Stack>
  </>;
};

export default TaskInfo;
