/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';
import { Card, Container, Grid, Stack } from '@mui/material';
import { useParams } from 'react-router';
import useTask from '../useTask';
import Form from '../../../components/Form';
import Page from '../../../components/Page';
import TaskDetailEditor from '../TaskDetailEditor';
import Task from '../../../models/Task';
import useUpdateTask from '../useUpdateTask';
import TaskInfo from '../TaskInfo';
import FileByType from '../FilesByType';
import { FileType } from '../../../enums/FileType';
import CommentsSection from '../CommentsSection';
import TaskLoading from '../TaskLoading';
import { UserRole } from '../../../enums/UserRole';
import { useQuery } from 'react-query';
import Customer from '../../../models/Customer';
import useSeenTask from '../useSeenTask';
import SelectField from '../../../components/Form/fields/SelectField';
import { taskStatusOptions } from '../../../enums/TaskStatus';
import StatusHandler from '../StatusHandler/StatusHandler';
import { LoadingButton } from '@mui/lab';


const TranslatorEditor: React.FC = () => {
  const { id: taskId } = useParams();
  const { data: task } = useTask(taskId);
  const { data: customer } = useQuery<Customer>(['customers/', task?.customerId], { enabled: !!task?.customerId });
  const { mutate: updateTask } = useUpdateTask(taskId);
  const { mutate: setSeen } = useSeenTask();

  useEffect(() => {
    setSeen({ id: taskId });
  }, [setSeen, taskId]);

  if (!task) {
    return <TaskLoading />;
  }

  return (
    <Page title="YLT" >
      <Form<Task>
        validationSchema={undefined} onSubmit={updateTask} initialValues={task}>
        <Container maxWidth={'lg'}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Stack gap={2}>
                <Card sx={{ p: 2 }}>
                  <TaskInfo task={task} />
                </Card>
                <FileByType fileType={FileType.TRANSLATED} taskId={taskId} />
                <FileByType fileType={FileType.ORIGINAL} taskId={taskId} />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack gap={2}>
                <Card sx={{ p: 2, bgcolor: 'background.neutral' }}>
                  <TaskDetailEditor disabled />
                </Card>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack gap={2}>
                <StatusHandler task={task} />
                <LoadingButton
                  fullWidth
                  variant="contained"
                  size="small"
                  color="secondary"
                  type={'submit'}>
                  Save task
                </LoadingButton>

                <CommentsSection />

              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Form>
    </Page>
  );
};

export default TranslatorEditor;
