/* eslint-disable no-unused-vars */
export enum TaskStatus {
    NEW_TASK = 'new_task',
    ASSIGNED = 'assigned',
    INVALID_FILE = 'invalid_file',
    TRANSLATING = 'translating',
    READY_FOR_DOWNLOAD = 'ready_for_download',
    FINAL_FILES_PREPARATION = 'final_files_preparation',
    PROOFREADING = 'proofreading',
}

export const taskStatusOptions = [
    { name: 'New task', id: TaskStatus.NEW_TASK },
    { name: 'Assigned', id: TaskStatus.ASSIGNED },
    { name: 'Invalid file', id: TaskStatus.INVALID_FILE },
    { name: 'Translating', id: TaskStatus.TRANSLATING },
    { name: 'Ready for download', id: TaskStatus.READY_FOR_DOWNLOAD },
    { name: 'Final files preparation', id: TaskStatus.FINAL_FILES_PREPARATION },
    { name: 'Proofreading', id: TaskStatus.PROOFREADING },
];
