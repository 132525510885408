import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import roundChat from '@iconify/icons-ic/round-chat';
import roundArticle from '@iconify/icons-ic/round-article';
import roundLanguage from '@iconify/icons-ic/round-language';
import { Container, Tab, Box, Tabs, Typography } from '@mui/material';
import Page from '../../components/Page';
import Languages from '../../components/admin/settings/Languages/Languages';
import Holidays from './Holidays';
import ChatOptions from '../../components/admin/settings/ChatOptions/ChatOptions';
import BillingDetails from '../../components/admin/settings/BillingDetails';


export default function AdminSettings(): JSX.Element {
    const [currentTab, setCurrentTab] = useState('Languages');

    const SETTINGS_TABS = [
        {
            value: 'Languages',
            icon: <Icon icon={roundLanguage} width={20} height={20} />,
            component: <Languages />,
        },
        {
            value: 'Chat',
            icon: <Icon icon={roundChat} width={20} height={20} />,
            component: <ChatOptions />,
        },
        {
            value: 'Admin billing details',
            icon: <Icon icon={roundArticle} width={20} height={20} />,
            component: <BillingDetails />,
        },
        {
            value: 'Holidays',
            icon: <Icon icon={'mdi:island'} width={20} height={20} />,
            component: <Holidays />,
        },

    ];

    return (
        <Page title="YLT">
            <Container maxWidth={'lg'}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 5, justifyContent: 'space-between' }}>
                    <Typography variant="h4" gutterBottom >Platform Settings</Typography>
                </Box>

                <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    allowScrollButtonsMobile
                    onChange={(e, value) => setCurrentTab(value)}
                >
                    {SETTINGS_TABS.map((tab) => (
                        <Tab
                            disableRipple
                            key={tab.value}
                            label={tab.value}
                            icon={tab.icon}
                            value={tab.value}
                        />
                    ))}
                </Tabs>

                <Box sx={{ mb: 5 }} />

                {SETTINGS_TABS.map((tab) => {
                    const isMatched = tab.value === currentTab;
                    return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                })}
            </Container>
        </Page>
    );
}
