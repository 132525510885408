import React, { useMemo, useState } from 'react';
import {
    Box,
    Card,
    Container,
    Tab,
    Tabs,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import CustomerCover from '../../components/CustomerCover';
import Page from '../../components/Page';
import Customer from '../../models/Customer';
import getCustomerTabs from './Tabs';


const TabsWrapperStyle = styled('div')(({ theme }) => ({
    zIndex: 9,
    bottom: 0,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
    },
}));

export default function CustomerPage(): JSX.Element {
    const { id: customerId } = useParams();
    const { data: customer } = useQuery<Customer>(['customers', '/', customerId]);

    const [currentTab, setCurrentTab] = useState('Details');

    const CUSTOMER_TABS = useMemo(() => {
        return getCustomerTabs(customer);
    }, [customer]);

    const handleChangeTab = (newValue: string) => {
        setCurrentTab(newValue);
    };

    return (
        <Page title="YLT">
            <Container maxWidth={'lg'}>
                <Card
                    sx={{
                        mb: 3,
                        height: 280,
                        position: 'relative',
                    }}
                >
                    <CustomerCover />

                    <TabsWrapperStyle>
                        <Tabs
                            value={currentTab}
                            scrollButtons="auto"
                            variant="scrollable"
                            allowScrollButtonsMobile
                            onChange={(e, value) => handleChangeTab(value)}
                        >
                            {CUSTOMER_TABS.map((tab) => (
                                <Tab
                                    disableRipple
                                    key={tab.value}
                                    value={tab.value}
                                    icon={tab.icon}
                                    label={tab.value}
                                />
                            ))}
                        </Tabs>
                    </TabsWrapperStyle>
                </Card>

                {CUSTOMER_TABS.map((tab) => {
                    const isMatched = tab.value === currentTab;
                    return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                })}
            </Container>
        </Page>
    );
}

