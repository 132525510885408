import React, { ReactElement, ReactNode } from 'react';
import baselineList from '@iconify/icons-ic/baseline-list';
import baselineUser from '@iconify/icons-ic/baseline-supervised-user-circle';
import { Icon } from '@iconify/react';
import Details from '../../components/customer/Details/Details';
import Employees from '../../components/customer/employees/Employees';
import Customer from '../../models/Customer';

type CustomerTab = {
    value: string
    icon: ReactElement
    component: ReactNode
}

function getCustomerTabs(customer?: Customer): CustomerTab[] {
    const CUSTOMER_TABS = [
        {
            value: 'Details',
            icon: <Icon icon={baselineList} width={20} height={20} />,
            component: <Details customer={customer} />,
        },
        {
            value: 'Employees',
            icon: <Icon icon={baselineUser} width={20} height={20} />,
            component: <Employees />,
        },
    ];

    return CUSTOMER_TABS;
}

export default getCustomerTabs;
