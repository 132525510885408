import moment from 'moment';
import Actions from './Actions';
import React from 'react';
import Customer from '../../../models/Customer';
import Column from '../../../components/ConnectedTable/Column';

const columns: Column<Customer>[] = [
    { id: 'name', label: 'Name' },
    { id: 'translatedPageNumber', label: 'Pages translated' },
    {
        id: 'created',
        label: 'Added at',
        getter(customer) {
            return moment(customer.created).format('YYYY MMM. DD');
        },
    },
    {
        id: 'modified', width: 60, label: '', getter(customer) {
            return <Actions customer={customer} />;
        },
    },
];

export default columns;
