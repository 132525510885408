/* eslint-disable indent */
import { BoxProps, Grid, Tooltip } from '@mui/material';
import { alpha, styled, Theme, useTheme } from '@mui/material/styles';
import React from 'react';
import { ColorSchema } from '../@types/theme';
import { TaskStatus } from '../enums/TaskStatus';


type LabelColor = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

type LabelVariant = 'filled' | 'outlined' | 'ghost';

const RootStyle = styled('span')(
    ({
        theme,
        styleprops,
    }: {
        theme: Theme;
        styleprops: {
            color: LabelColor;
            variant: LabelVariant;
        };
    }) => {
        const isLight = theme.palette.mode === 'light';
        const { color, variant } = styleprops;

        const styleFilled = (color: ColorSchema) => ({
            color: theme.palette[color].contrastText,
            backgroundColor: theme.palette[color].main,
        });

        const styleOutlined = (color: ColorSchema) => ({
            color: theme.palette[color].main,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette[color].main}`,
        });

        const styleGhost = (color: ColorSchema) => ({
            color: theme.palette[color][isLight ? 'dark' : 'light'],
            backgroundColor: alpha(theme.palette[color].main, 0.16),
        });

        return {
            height: 22,
            minWidth: 22,
            lineHeight: 0,
            borderRadius: 8,
            cursor: 'default',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            display: 'inline-flex',
            justifyContent: 'center',
            padding: theme.spacing(0, 1),
            // color: theme.palette.grey[800],
            fontSize: theme.typography.pxToRem(12),
            fontFamily: theme.typography.fontFamily,
            // backgroundColor: theme.palette.grey[300],
            fontWeight: theme.typography.fontWeightBold,

            ...(color !== 'default' ?
                {
                    ...(variant === 'filled' && { ...styleFilled(color) }),
                    ...(variant === 'outlined' && { ...styleOutlined(color) }),
                    ...(variant === 'ghost' && { ...styleGhost(color) }),
                } :
                {
                    ...(variant === 'outlined' && {
                        backgroundColor: 'transparent',
                        color: theme.palette.text.primary,
                        border: `1px solid ${theme.palette.grey[500_32]}`,
                    }),
                    ...(variant === 'ghost' && {
                        color: isLight ? theme.palette.text.secondary : theme.palette.common.white,
                        backgroundColor: theme.palette.grey[500_16],
                    }),
                }),
        };
    },
);

interface LabelProps extends BoxProps {
    color?: LabelColor;
    variant?: LabelVariant;
    taskStatus?: TaskStatus;
    buttons?: JSX.Element;
}

export default function Label({
    taskStatus,
    color = 'default',
    variant = 'ghost',
    children,
    sx, buttons }: LabelProps): JSX.Element {
    const theme = useTheme();

    if (color === 'default') {
        switch (taskStatus) {
            case TaskStatus.READY_FOR_DOWNLOAD:
            case TaskStatus.FINAL_FILES_PREPARATION:
                color = 'success';
                break;
            case TaskStatus.INVALID_FILE:
                color = 'error';
                break;
            case TaskStatus.PROOFREADING:
            case TaskStatus.NEW_TASK:
            case TaskStatus.TRANSLATING:
                color = 'info';
                break;
            default: color = 'warning';
        }
    }

    const getTooltipTitle = () => {
        return (
            <Grid container direction='column'>
                {buttons}
            </Grid>
        );
    };

    if (buttons) {
        return (
            <Tooltip title={getTooltipTitle()} placement='top'>
                <RootStyle styleprops={{ color, variant }} sx={sx} theme={theme}>
                    {children}
                </RootStyle>
            </Tooltip>
        );
    } else {
        return (
            <RootStyle styleprops={{ color, variant }} sx={sx} theme={theme}>
                {children}
            </RootStyle>
        );
    }
}
