import React from 'react';
import { Button, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import filter from '@iconify/icons-ant-design/filter-outline';
import { Icon } from '@iconify/react';
import Customer from '../../../models/Customer';
import AutoComplete from '../../../components/Form/fields/AutoComplete';
import useQueryString from '../../../hooks/useQueryString';
import Form from '../../../components/Form';
import DateRangePicker from '../../../components/Form/fields/DateRangePicker';
import SelectMultiple from '../../../components/Form/fields/SelectMultiple';
import { taskStatusOptions } from '../../../enums/TaskStatus';


type FilterForm = {
    customerId?: string
    targetLang?: string
    sourceLang?: string
    before?: string
    after?: string
    status?: string[]
}

const emptyFilters: FilterForm = {
    'customerId': '',
    'before': '',
    'after': '',
    'sourceLang': '',
    'targetLang': '',
    'status': [],
};


const Filters: React.FC = () => {
    const [query, setQuery] = useQueryString();

    const onSubmit = (form: FilterForm) => {
        setQuery({ ...emptyFilters, ...form });
    };

    return (
        <Form<FilterForm>
            validationSchema={undefined}
            onSubmit={onSubmit}
            initialValues={{
                ...emptyFilters,
                ...query,
            }}
        >
            <Grid container spacing={1} sx={{ p: 1 }}>
                <Grid item xs={12}>
                    <SelectMultiple options={taskStatusOptions.map((op) => op.id)} name={'status'} label={'Task statuses'} />
                </Grid>
                <Grid item xs={6}>
                    <AutoComplete name={'customerId'} label={'Customer'} queryKey={'customers'} queryOptions={{
                        select: (res: any) => res.customers.map((cust: Customer) => ({
                            id: cust.id ?? uuidv4(),
                            name: cust.name ?? 'Missing name',
                        })),
                    }} />
                </Grid>
                <Grid item xs={6}>
                    <DateRangePicker lowerBoundLabel={'Created after'} upperBoundLabel={'Created before'} upperBoundName={'before'} lowerBoundName={'after'} />
                </Grid>
                <Grid item xs={4}>
                    <AutoComplete name={'sourceLang'} label={'Source language'} queryKey={'languages'} />
                </Grid>
                <Grid item xs={4}>
                    <AutoComplete name={'targetLang'} label={'Target language'} queryKey={'languages'} />
                </Grid>

                <Grid item xs={4}>
                    <Button sx={{ height: '100%' }} startIcon={<Icon icon={filter} />} fullWidth variant={'contained'} type={'submit'}>Filter</Button>
                </Grid>
            </Grid>
        </Form>
    );
};

export default Filters;
