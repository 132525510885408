import moment from 'moment';
import Label from '../../../components/Label';
import Column from '../../../components/ConnectedTable/Column';
import Task from '../../../models/Task';

const columns: Column<Task>[] = [
    { id: 'taskId', label: 'Task ID' },
    {
        id: 'created', label: 'Created', getter: (task) => task.created ?
            moment(task.created)?.format('MM/DD/YY hh:mm A') :
            '-',
    },
    {
        id: 'finishedAt', label: 'Finished', getter: (task) => task.finishedAt ?
            moment(task.finishedAt)?.format('MM/DD/YY hh:mm A') :
            '-',
    },
    {
        id: 'approvedAt', label: 'Approved', getter: (task) => task.approvedAt ?
            moment(task.approvedAt)?.format('MM/DD/YY hh:mm A') :
            '-',
    },
    {
        id: 'status',
        width: 170,
        label: 'Status', getter: (task) => <Label
            variant={'ghost'}
            taskStatus={task.status}
        >
            {task.status?.split('_').join(' ')}
        </Label>,
    },

];

export default columns;
