import React from 'react';
import useFilesByTask from './useFilesByTask';
import ListFiles from '../../../components/ListFiles';
import { FileType } from '../../../enums/FileType';
import useMe from '../../../hooks/useMe';
import { TaskStatus } from '../../../enums/TaskStatus';
import { Box, Card, Chip, Stack, Tooltip, Typography } from '@mui/material';
import FileUpload from '../FileUpload';
import { UserRole } from '../../../enums/UserRole';
import numeral from 'numeral';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import AutorenewTwoToneIcon from '@mui/icons-material/AutorenewTwoTone';
import { TranslationFileParseStatus } from '../../../models/Task';

type Props = {
    taskId?: string;
    taskStatus?: TaskStatus;
    fileType: FileType
    charCount?: number | null;
    parseStatus?: TranslationFileParseStatus;
    bgColor?: string;
}

const FileByType: React.FC<Props> = ({ taskId, parseStatus, charCount, fileType, taskStatus, bgColor }) => {
    const { data: files } = useFilesByTask(fileType, taskId);
    const { data: currentUser } = useMe();

    const checkUploadAccess = (role?: UserRole) => {
        if (fileType === FileType.TRANSLATED) {
            return role && role !== UserRole.USER && role !== UserRole.AGENT;
        } else if (fileType === FileType.ORIGINAL) {
            return role && role !== UserRole.TRANSLATOR;
        } else {
            return false;
        }
    };

    if (files?.length === 0 && checkUploadAccess(currentUser?.role)) {
        return (
            <Card sx={{ p: 2, bgcolor: bgColor }}>
                <Typography variant='subtitle2' color='text.secondary' sx={{ textTransform: 'capitalize' }} gutterBottom>
                    {fileType + ' file'}
                </Typography>
                <FileUpload taskId={taskId} type={fileType} />
            </Card>
        );
    } else if (files?.length === 0 && !checkUploadAccess(currentUser?.role)) {
        return (
            <Card sx={{ p: 2 }}>
                <Stack alignItems={'center'}>
                    <Typography alignSelf={'flex-start'} sx={{ textTransform: 'capitalize' }}
                        variant='subtitle2' color='text.secondary' gutterBottom>{fileType + ' file'}</Typography>
                    <Stack alignItems={'center'} justifyContent={'center'} gap={2}>
                        <img width={140} alt="empty content"
                            src="/images/illustration_empty_content.svg" />
                        <Typography variant={'subtitle2'}>Not available yet</Typography>
                    </Stack>
                </Stack>
            </Card>
        );
    }

    return (
        <Card sx={{ p: 2 }}>
            <Box>
                <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'} mb={1}>
                    <Typography variant='subtitle2' color='text.secondary' sx={{ textTransform: 'capitalize' }}>
                        {fileType + ' file'}
                    </Typography>
                    {parseStatus === 'parsing' && <Tooltip title={'Counting characters in file...'}>
                        <AutorenewTwoToneIcon fontSize={'small'} color={'primary'} />
                    </Tooltip>}
                    {parseStatus === 'error' && <Tooltip title={'File could not be processed for character count'}>
                        <WarningTwoToneIcon fontSize={'small'} color={'warning'} />
                    </Tooltip>}
                    {charCount && <Chip variant={'outlined'} size={'small'} color={'info'} label={`${numeral(charCount).format(',')} characters`} />}
                </Stack>
                {fileType === FileType.TRANSLATED && checkUploadAccess(currentUser?.role) && (
                    <FileUpload taskId={taskId} type={fileType} />
                )}
                <ListFiles
                    disableDelete={parseStatus === 'parsing'}
                    files={files ?? []}
                    previewable={true}
                    downloadable={true}
                    taskId={taskId}
                    taskStatus={taskStatus}
                    currentUser={currentUser}
                    translation={fileType === FileType.TRANSLATED ? true : false}
                />
            </Box>
        </Card>
    );
};

export default FileByType;
