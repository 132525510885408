import Tabs from './Tabs';
import ConnectedTable from '../../components/ConnectedTable';
import Task from '../../models/Task';
import getColumns from './getColumns';
import Page from '../../components/Page';
import Filters from './Filters';
import { Button, Card, Container, Stack } from '@mui/material';
import add from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { UserRole } from '../../enums/UserRole';
import useMe from '../../hooks/useMe';
import { Link as RouterLink } from 'react-router-dom';
import { useMemo } from 'react';
import PageHeader from '../../components/PageHeader';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';

const TaskList: React.FC = () => {
    const { isCollapse } = useCollapseDrawer();

    const { data: profile } = useMe();
    const canAddNewTask = profile && [UserRole.ADMIN, UserRole.USER, UserRole.AGENT].includes(profile?.role);

    const columns = useMemo(() => {
        return getColumns(profile?.role);
    }, [profile?.role]);

    return (
        <Page title={'Task list page'}>
            <Container maxWidth={isCollapse ? false : 'lg'}>
                <PageHeader
                    title={'Tasks'}
                    actions={(
                        <>
                            {canAddNewTask && <Button
                                component={RouterLink}
                                to="/tasks/new"
                                startIcon={<Icon icon={add} />}
                                variant={'contained'}>New task</Button>}
                        </>
                    )} />
                <Card>
                    <Tabs />
                    <Filters />
                    <Stack px={1}>
                        <ConnectedTable<Task>
                            defaultFilters={{ section: 'actionNeeded' }}
                            queryKey={'tasks'}
                            idKey={'taskId'}
                            columns={columns} />
                    </Stack>
                </Card>
            </Container>
        </Page>
    );
};

export default TaskList;
