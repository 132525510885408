import closeFill from '@iconify/icons-eva/close-fill';
import downloadFill from '@iconify/icons-eva/download-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import { Icon } from '@iconify/react';
import {
    List,
    ListItem,
    ListItemIcon, ListItemText, Stack, Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { TaskStatus } from '../enums/TaskStatus';
import { UserRole } from '../enums/UserRole';
import CustomFile from '../interfaces/CustomFile';
import File from '../models/File';
import User from '../models/User';
import DeleteFileDialog from '../pages/TaskEditor/FilesByType/DeleteFileDialog';
import useAPI from '../utils/ApiClient';
import convertBytes from '../utils/ByteConverter';
import { MIconButton } from './@material-extend';

type ListFilesProps = {
    files: File[],
    onDelete?: (id?: string) => void,
    downloadable: boolean,
    previewable: boolean,
    taskId?: string,
    userId?: string,
    currentUser?: User,
    taskStatus?: string;
    translation?: boolean;
    disableDelete?: boolean;
    notUploadedYet?: boolean;
    customFiles?: CustomFile[];
    setFiles?: React.Dispatch<React.SetStateAction<CustomFile[]>>;
}

export default function ListFiles(props: ListFilesProps): JSX.Element {
    const [openFileDeletion, setOpenFileDeletion] = useState<boolean>(false);

    const [, downloadFile] = useAPI({ method: 'GET' }, { manual: true });
    const [, previewFile] = useAPI({ method: 'GET' }, { manual: true });

    const handlePreview = async (id?: string) => {
        const url = props.taskId ?
            `/tasks/${props.taskId}/files/${id}/preview` :
            `/users/${props.userId}/files/${id}/preview`;
        const previewUrl = (await previewFile({ url })).data;
        const pdfWindow = window.open();
        if (pdfWindow) {
            pdfWindow.location.href = previewUrl;
        }
    };

    const handleDownload = async (id?: string) => {
        const url = props.taskId ?
            `/tasks/${props.taskId}/files/${id}/download` :
            `/users/${props.userId}/files/${id}/download`;
        const downloadUrl = (await downloadFile({ url })).data;
        location.href = downloadUrl;
    };

    const checkOriginalDelete = (user?: User, status?: string) => {
        if (user && user.role === UserRole.ADMIN) return true;
        if (status === TaskStatus.INVALID_FILE || status === TaskStatus.NEW_TASK) {
            return user && (user.role === UserRole.USER || user.role === UserRole.AGENT);
        }
        return false;
    };

    const checkTranslationDelete = (user?: User, status?: string) => {
        if (user && user.role === UserRole.ADMIN) return true;
        if (status !== TaskStatus.PROOFREADING && status !== TaskStatus.READY_FOR_DOWNLOAD && status !== TaskStatus.FINAL_FILES_PREPARATION) {
            return user && (user.role === UserRole.TRANSLATOR);
        }
        return false;
    };

    const removeFileFromState = (id?: string) => {
        if (props.setFiles && props.customFiles && id) {
            const newArray = props.customFiles.filter((file) => file.id !== id);
            props.setFiles(newArray);
        }
    };

    return (
        <List>
            {props.files.map((file) => (
                <ListItem
                    key={file.id}
                    component={'div'}
                    sx={{
                        py: 0.5,
                        px: 1,
                        borderRadius: 1,
                        border: (theme) => `solid 1px ${theme.palette.divider}`,
                        bgcolor: 'background.paper',
                        my: 1,
                    }}
                >
                    <ListItemIcon>
                        <Icon icon={fileFill} width={28} height={28} />
                    </ListItemIcon>
                    <ListItemText
                        primary={file.filename}
                        secondary={convertBytes(file.size ?? 0)}
                        primaryTypographyProps={{
                            variant: 'subtitle2', sx: {
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            },
                        }}
                        secondaryTypographyProps={{ variant: 'caption' }}
                    />
                    <Stack direction={'row'}>
                        {props.downloadable ? (
                            <Tooltip title={'Download'}>
                                <MIconButton edge="end" size="small" onClick={() => handleDownload(file.id)}>
                                    <Icon icon={downloadFill} />
                                </MIconButton>
                            </Tooltip>
                        ) : null}

                        {props.previewable ? (
                            <Tooltip title={'Preview'}>
                                <MIconButton edge="end" size="small" onClick={() => handlePreview(file.id)}>
                                    <Icon icon={eyeFill} />
                                </MIconButton>
                            </Tooltip>
                        ) : null}

                        {!props.translation && checkOriginalDelete(props.currentUser, props.taskStatus) ? (
                            <Tooltip title={'Delete'}>
                                <MIconButton disabled={props.disableDelete} edge="end" size="small" onClick={() => setOpenFileDeletion(true)}>
                                    <Icon style={{ color: 'red' }} icon={closeFill} />
                                </MIconButton>
                            </Tooltip>
                        ) : null}

                        {props.translation && checkTranslationDelete(props.currentUser, props.taskStatus) ? (
                            <Tooltip title={'Delete'}>
                                <MIconButton edge="end" size="small" onClick={() => setOpenFileDeletion(true)}>
                                    <Icon style={{ color: 'red' }} icon={closeFill} />
                                </MIconButton>
                            </Tooltip>
                        ) : null}

                        {props.notUploadedYet ? (
                            <Tooltip title={'Delete'}>
                                <MIconButton edge="end" size="small" onClick={() => removeFileFromState(file.id)}>
                                    <Icon style={{ color: 'red' }} icon={closeFill} />
                                </MIconButton>
                            </Tooltip>
                        ) : null}
                    </Stack>

                    <DeleteFileDialog
                        open={openFileDeletion}
                        onClose={() => setOpenFileDeletion(false)}
                        taskId={props.taskId ?? ''}
                        fileId={file.id ?? ''}
                    />
                </ListItem>
            ))}

        </List>
    );
}
