import { Card, CardHeader, TextField } from '@mui/material';
import * as React from 'react';
import { useMemo } from 'react';
import HorizontalBarChart from '../../../../../components/charts/HorizontalBarChart';
import { useQuery } from 'react-query';

type ChartData = {
    year: number
    month: number
    count: number
}

const currentYear = new Date().getFullYear();

const years = [
    currentYear - 2, currentYear - 1, currentYear,
];

const TasksCreated: React.FC = () => {
    const [seriesData, setSeriesData] = React.useState(2022);
    const { data } = useQuery<ChartData[]>(`/dashboard/tasks-created?year=${seriesData}`);

    function getMonthName(monthNumber: number) {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][monthNumber - 1];
    }

    const handleChangeSeriesData = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSeriesData(Number(event.target.value));
    };

    const chartData = useMemo(() => {
        return data?.filter((item) => item.year === seriesData).reduce<{ data: number[]; categories: string[] }>((acc, curr) => {
            acc.data.push(curr.count ?? 0);
            acc.categories.push(getMonthName(curr.month) ?? 'Missing name');
            return acc;
        }, { data: [], categories: [] });
    }, [data, seriesData]);

    return (
        <Card>
            <CardHeader
                title="Tasks Created"
                action={
                    <TextField
                        select
                        fullWidth
                        value={seriesData}
                        SelectProps={{ native: true }}
                        onChange={handleChangeSeriesData}
                        sx={{
                            '& fieldset': { border: '0 !important' },
                            '& select': { pl: 1, py: 0.5, pr: '24px !important', typography: 'subtitle2' },
                            '& .MuiOutlinedInput-root': { borderRadius: 0.75, bgcolor: 'background.neutral' },
                            '& .MuiNativeSelect-icon': { top: 4, right: 0, width: 20, height: 20 },
                        }}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </TextField>
                } />
            <HorizontalBarChart
                data={chartData?.data ?? []}
                categories={chartData?.categories ?? []}
            />
        </Card>
    );
};

export default TasksCreated;
