import { IconButton, Stack, Tooltip } from '@mui/material';
import { Icon as Iconify } from '@iconify/react';
import Task from '../../../models/Task';
import reopenIcon from '@iconify/icons-ic/outline-open-in-new';
import React from 'react';
import { TaskStatus } from '../../../enums/TaskStatus';
import { UserRole } from '../../../enums/UserRole';
import useReopenTask from './useReopenTask';
import useDeleteTask from './useDeleteTask';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import ControlledDialog from '../../../components/ControlledDialog';
import useGetTranslatedFile from './useGetTranslatedFile';
import { API } from '../../../utils/ApiClient';

const TaskActions: React.FC<{ task: Task; role: UserRole }> = ({ task, role }) => {
    const { mutate: reopenTask } = useReopenTask();
    const { mutate: deleteTask } = useDeleteTask();
    const { data: files, refetch } = useGetTranslatedFile(task.id);

    const downloadTranslation = async () => {
        refetch();
        if (files) {
            for (const f of files) {
                const file = await API.get(`/tasks/${task.id}/files/${f.id}/download`);
                window.open(file.data);
            }
        }
    };

    const isCustomerAndNewTask = (role === UserRole.USER || role === UserRole.AGENT) && task.status === TaskStatus.NEW_TASK;
    const isAdmin = role === UserRole.ADMIN;
    const showDeleteIcon = isCustomerAndNewTask || isAdmin;

    const showReopenTask = task.status === TaskStatus.READY_FOR_DOWNLOAD && role !== UserRole.TRANSLATOR;


    return (
        <Stack direction={'row'} justifyContent={'flex-end'}>
            {showDeleteIcon && (
                <ControlledDialog
                    title={'Are you sure?'}
                    content={'This action is irreversible.'}
                    onSubmit={() => deleteTask({ id: task.id })} submitLabel={'Delete'} trigger={
                        <IconButton color={'error'}>
                            <Iconify fontSize={18} icon={trash2Outline} />
                        </IconButton>
                    } />
            )}


            {task.files && task.files[0] && task?.files?.[0].id ?
                <Tooltip title={'Download file'}>
                    <IconButton onClick={downloadTranslation}>
                        <Iconify fontSize={18} icon={'eva:download-fill'} />
                    </IconButton>
                </Tooltip> :
                <IconButton disabled>
                    <Iconify fontSize={18} icon={'eva:download-fill'} />
                </IconButton>}

            {showReopenTask && (
                <Tooltip title={'Reopen task'}>
                    <IconButton onClick={() => reopenTask({ taskId: task.id })}>
                        <Iconify fontSize={18} icon={reopenIcon} />
                    </IconButton>
                </Tooltip>
            )}
        </Stack>
    );
};


export default TaskActions;
