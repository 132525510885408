/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';
import { Card, Container, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router';
import useTask from '../useTask';
import Form from '../../../components/Form';
import Page from '../../../components/Page';
import TaskDetailEditor from '../TaskDetailEditor';
import Task from '../../../models/Task';
import { TaskStatus } from '../../../enums/TaskStatus';
import useUpdateTask from '../useUpdateTask';
import FileByType from '../FilesByType';
import { FileType } from '../../../enums/FileType';
import Modifications from '../Modifications';
import CommentsSection from '../CommentsSection';
import TaskLoading from '../TaskLoading';
import TextField from '../../../components/Form/fields/TextField';
import useSeenTask from '../useSeenTask';
import { LoadingButton } from '@mui/lab';
import EditTaskSchema from './EditTaskSchema';


const AgentEditor: React.FC = () => {
    const { id: taskId } = useParams();
    const { data: task } = useTask(taskId);
    const { mutate: updateTask } = useUpdateTask(taskId);
    const { mutate: setSeen } = useSeenTask();

    useEffect(() => {
        setSeen({ id: taskId });
    }, [setSeen, taskId]);


    if (!task) {
        return <TaskLoading />;
    }

    return (
        <Page title="YLT" >
            <Form<Task>
                validationSchema={EditTaskSchema} onSubmit={updateTask} initialValues={task}>
                <Container maxWidth={'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <Card sx={{ p: 2 }}>
                                    <Stack gap={2}>
                                        <Typography variant='subtitle2' gutterBottom>
                                            Task ID
                                        </Typography>
                                        <TextField
                                            name={'taskId'}
                                            label={'ID'}
                                            disabled
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">#</InputAdornment>,
                                            }} />
                                    </Stack>
                                </Card>
                                <FileByType fileType={FileType.TRANSLATED} taskId={taskId} />
                                <FileByType fileType={FileType.ORIGINAL} taskId={taskId} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>
                                <Card sx={{ p: 2, bgcolor: 'background.neutral' }}>
                                    <TaskDetailEditor disabled={
                                        task.status === TaskStatus.NEW_TASK ?
                                            false : true
                                    } />
                                </Card>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack gap={2}>

                                <CommentsSection />

                                <Modifications taskId={taskId} />

                                <Card sx={{ p: 2 }}>
                                    <TextField name={'company'} label={'Company'} />
                                </Card>

                                <LoadingButton
                                    fullWidth
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    type={'submit'}>
                                    Save task
                                </LoadingButton>

                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Form>
        </Page>
    );
};

export default AgentEditor;
