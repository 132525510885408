import { Button, Card, Container, DialogTitle, Stack } from '@mui/material';
import PageHeader from '../../../components/PageHeader';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import ConnectedTable from '../../../components/ConnectedTable';
import Page from '../../../components/Page';
import User from '../../../models/User';
import React, { useMemo, useState } from 'react';
import Filters from './Filters';
import useMe from '../../../hooks/useMe';
import UserForm from '../../../components/admin/UserForm';
import DialogAnimate from '../../../components/animate/DialogAnimate';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import getColumns from './getColumns';

const UserList: React.FC = () => {
    const { isCollapse } = useCollapseDrawer();
    const { data: me } = useMe();
    const [isOpen, setIsOpen] = useState(false);

    const columns = useMemo(() => {
        if (!me?.role) {
            return [];
        }
        return getColumns(me.role);
    }, [me?.role]);


    return (
        <Page title={'Users'}>
            <Container maxWidth={isCollapse ? false : 'lg'}>
                <PageHeader
                    title={'Users'}
                    actions={<Button
                        variant="contained"
                        onClick={() => setIsOpen(true)}
                        startIcon={<Icon icon={plusFill} />}
                    >
                        {'New User'}
                    </Button>} />
                <Card>
                    <Filters />
                    <Stack px={2}>
                        <ConnectedTable<User>
                            queryKey={'users'}
                            idKey={'id'}
                            columns={columns} />
                    </Stack>
                </Card>
            </Container>
            <DialogAnimate open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>New user</DialogTitle>
                <UserForm onClose={() => setIsOpen(false)} />
            </DialogAnimate>
        </Page>
    );
};

export default UserList;
