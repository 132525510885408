import React from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { IconButton, Stack, Typography } from '@mui/material';
import SelectRemoteOptions from '../../../components/Form/fields/SelectRemoteOptions';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import TranslatorSelector from '../TranslatorSelector';
import { useFormikContext } from 'formik';
import Task from '../../../models/Task';
import TaskLanguage from '../../../models/TaskLanguage';

const TaskDetailEditor: React.FC<{ showTranslationOptions?: boolean, disabled?: boolean }> = ({ showTranslationOptions, disabled }) => {
    const { values: task, setFieldValue } = useFormikContext<Task>();

    const onAddLanguage = (type: 'sourceLanguages' | 'targetLanguages') => {
        const newLanguage: TaskLanguage = {
            language: undefined, languageId: '',

        };
        if (type === 'sourceLanguages') {
            setFieldValue('sourceLanguages', task.sourceLanguages ? [...task.sourceLanguages, newLanguage] : [newLanguage]);
        } else {
            setFieldValue('targetLanguages', task.targetLanguages ? [...task.targetLanguages, newLanguage] : [newLanguage]);
        }
    };

    function removeLanguageByIndex(langIndex: number, type: 'sourceLanguages' | 'targetLanguages') {
        if (type === 'sourceLanguages') {
            const langArray = task.sourceLanguages ? [...task.sourceLanguages] : [];
            setFieldValue('sourceLanguages', langArray.filter((_, index) => index !== langIndex));
        } else {
            const langArray = task.sourceLanguages ? [...task.sourceLanguages] : [];
            setFieldValue('targetLanguages', langArray.filter((_, index) => index !== langIndex));
        }
    }

    return (
        <Stack spacing={2}>
            {showTranslationOptions && (
                <TranslatorSelector />
            )}
            <Typography variant='subtitle2'>From:</Typography>
            {task.sourceLanguages?.map((language, index) => (
                <Stack direction={'row'} spacing={2} key={language.id} sx={{ my: 1 }}>
                    <SelectRemoteOptions label={'From'} name={`sourceLanguages[${index}].id`} queryKey={'languages'} disabled={disabled} />
                    <IconButton onClick={() => removeLanguageByIndex(index, 'sourceLanguages')} disabled={disabled}>
                        <Icon icon={trash2Outline} width={20} height={20} />
                    </IconButton>
                </Stack>
            ))}
            <Stack direction='column' gap={2} alignItems="center">
                <IconButton onClick={() => onAddLanguage('sourceLanguages')} disabled={disabled}>
                    <Icon icon={plusFill} width={20} height={20} />
                </IconButton>
            </Stack>
            <Typography variant='subtitle2'>To:</Typography>
            {task.targetLanguages?.map((language, index) => (
                <Stack direction={'row'} spacing={2} key={language.id} sx={{ my: 1 }}>
                    <SelectRemoteOptions label={'To'} name={`targetLanguages[${index}].id`} queryKey={'languages'} disabled={disabled} />
                    <IconButton onClick={() => removeLanguageByIndex(index, 'targetLanguages')} disabled={disabled}>
                        <Icon icon={trash2Outline} width={20} height={20} />
                    </IconButton>
                </Stack>
            ))}
            <Stack direction='column' gap={2} alignItems="center">
                <IconButton onClick={() => onAddLanguage('targetLanguages')} disabled={disabled}>
                    <Icon icon={plusFill} width={20} height={20} />
                </IconButton>
            </Stack>
        </Stack>
    );
};

export default TaskDetailEditor;
