import { Box, Stack } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

type LogoProps = {
    redirectTo: string;
}

const ImageStyle = styled('img')(() => ({
    width: 105,
    objectFit: 'contain',
}));

export default function Logo(props: LogoProps): JSX.Element {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box component={RouterLink} to={props.redirectTo} sx={{ display: 'inline-flex' }}>
                <ImageStyle src="/static/icons/logo_cropped.png" alt="YLT" />
            </Box>
        </Stack>
    );
}
