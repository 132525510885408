import Column from './Column';
import * as React from 'react';
import { Paper, Stack, Table as MuiTable, TableBody, TableContainer, Typography } from '@mui/material';
import TableHead from './TableHead/TableHead';
import NoItemsToShow from './NoItemsToShow';
import SkeletonRows from './SkeletonRows';
import Rows from './Rows';
import SortModel from './SortModel';
import useQueryString from '../../hooks/useQueryString';
import useTableData from './useTableData';
import Pagination from './Pagination';
import Order from './Order';


type TableProps<T> = {
    columns: Column<T>[]
    idKey: keyof T
    onRowClick?: (row: T) => void
    queryKey: string
    defaultFilters?: Record<string, string>
    expandedComponent?: (row: T) => React.ReactNode
    summaryInfo?: string
}

const DEFAULT_PAGE_LIMIT = 25;

function ConnectedTable<T>({ queryKey, columns, idKey, onRowClick, defaultFilters, expandedComponent, summaryInfo }: TableProps<T>) {
    const { data, isLoading } = useTableData<T>(queryKey, defaultFilters);
    const isNoItemsToShow = !isLoading && data?.rows.length === 0;
    const [query] = useQueryString();

    const sortModel: SortModel<T> = {
        sort: query['sort'] as keyof T,
        order: query['order'] as Order,
        limit: query['limit'] ? Number(query['limit']) : DEFAULT_PAGE_LIMIT,
    };

    return (
        <>
            <TableContainer component={Paper}>
                <MuiTable sx={{ minWidth: 650, tableLayout: 'fixed' }}>
                    <TableHead<T> expandedComponent={expandedComponent} columns={columns} sortModel={sortModel} />
                    <TableBody>
                        {!!data?.rows && <Rows expandedComponent={expandedComponent} rows={data.rows} columns={columns} idKey={idKey} onRowClick={onRowClick} />}
                        {isLoading && <SkeletonRows<T> columns={columns} />}
                        {isNoItemsToShow && <NoItemsToShow numberOfColumns={columns.filter((c) => !c.isHidden).length} numberOfRows={sortModel.limit} />}
                    </TableBody>
                </MuiTable>
            </TableContainer>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                <></>
                <Pagination totalRows={data?.totalCount ?? 100} />
            </Stack>
        </>
    );
}

export default ConnectedTable;
