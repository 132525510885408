import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import { TextField } from '@mui/material';
import { useField } from 'formik';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/en-gb';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { useMemo } from 'react';


const DatePicker: React.FC<{ name: string; label: string; disablePast?: boolean }> = ({ name, disablePast, label }) => {
    const [field, meta] = useField(name);

    return useMemo(() => {
        const value = field.value || null;
        const setValue = (nextDate: Date | null) => {
            field.onChange({
                target: {
                    name: field.name,
                    value: nextDate ? moment(nextDate).format('YYYY-MM-DD') : null,
                },
            });
        };

        const hasError = Boolean(meta.touched && meta.error);
        const helperText = hasError && meta.error;

        return <LocalizationProvider dateAdapter={AdapterMoment} locale={'en-gb'}>
            <MuiDatePicker
                disablePast={disablePast}
                label={label}
                value={value}
                onChange={(e) => {
                    setValue(e);
                }}
                renderInput={(params) => <TextField fullWidth error={hasError} helperText={helperText} size={'small'} {...params} />}
            />
            {hasError}
        </LocalizationProvider>;
    }, [meta.touched, meta.error, label, disablePast, field]);
};

export default DatePicker;
