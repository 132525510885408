import {
    TableCell, TableRow,
} from '@mui/material';
import moment from 'moment';
import Task from '../../models/Task';
import Label from '../Label';

type RowProps = {
    task: Task;
    isLight: boolean;
}


export default function SummaryListRow({ task, isLight }: RowProps): JSX.Element {
    return (
        <>
            <TableRow key={task.id}>
                <TableCell>
                </TableCell>
                <TableCell>
                    {task.taskId}
                </TableCell>
                <TableCell>
                    {moment(task.created).format('DD/MM/YY HH:mm')}
                </TableCell>
                <TableCell>
                    {moment(task.finishedAt)?.format('MM/DD/YY hh:mm A')}
                </TableCell>
                <TableCell>
                    {moment(task.approvedAt)?.format('MM/DD/YY hh:mm A')}
                </TableCell>
                <TableCell>
                    <Label
                        variant={isLight ? 'ghost' : 'filled'}
                        taskStatus={task.status}
                    >
                        {task.status?.split('_').join(' ')}
                    </Label>
                </TableCell>
            </TableRow>
        </>
    );
}
