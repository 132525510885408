/* eslint-disable indent */
import SelectField from '../../../components/Form/fields/SelectField';
import { TaskStatus } from '../../../enums/TaskStatus';
import { UserRole } from '../../../enums/UserRole';
import useMe from '../../../hooks/useMe';
import Task from '../../../models/Task';

type StatusHandlerProps = {
    task: Task;
}

const StatusHandler = ({ task }: StatusHandlerProps) => {
    const { data: me } = useMe();

    const getSelectableStatuses = () => {
        if (task && task.status) {
            const newStatus = task.status.replace(/_/g, ' ');
            const styledStatus = newStatus.charAt(0).toUpperCase() + task.status.slice(1);
            switch (me && me.role) {
                case UserRole.ADMIN:
                    return [
                        { name: 'New task', id: TaskStatus.NEW_TASK },
                        { name: 'Assigned', id: TaskStatus.ASSIGNED },
                        { name: 'Invalid file', id: TaskStatus.INVALID_FILE },
                        { name: 'Translating', id: TaskStatus.TRANSLATING },
                        { name: 'Ready for download', id: TaskStatus.READY_FOR_DOWNLOAD },
                        { name: 'Final files preparation', id: TaskStatus.FINAL_FILES_PREPARATION },
                        { name: 'Proofreading', id: TaskStatus.PROOFREADING },
                    ];
                case UserRole.TRANSLATOR:
                    if (task.status === TaskStatus.ASSIGNED) {
                        return [
                            { name: 'Translating', id: TaskStatus.TRANSLATING },
                            { name: 'Invalid file', id: TaskStatus.INVALID_FILE },
                            { name: styledStatus, id: task.status },
                        ];
                    } else if (task.status === TaskStatus.TRANSLATING) {
                        return [
                            { name: 'Proofreading', id: TaskStatus.PROOFREADING },
                            { name: styledStatus, id: task.status },
                        ];
                    } else {
                        return [
                            { name: styledStatus, id: task.status },
                        ];
                    }
                default:
                    return [
                        { name: styledStatus, id: task.status },
                    ];
            }
        } else {
            return [
                { name: '', id: '' },
            ];
        }
    };


    return <SelectField
        options={getSelectableStatuses()}
        name={'status'}
        label={'Status'}
    />;
};

export default StatusHandler;
