import { Icon } from '@iconify/react';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
import roundVpnKey from '@iconify/icons-ic/round-vpn-key';
import ChangePassword from '../../components/profile/ChangePassword';
import { UserRole } from '../../enums/UserRole';
import MyHolidays from '../../components/profile/MyHolidays';
import React, { ReactElement, ReactNode } from 'react';
import User from '../../models/User';
import ProfileEditor from '../../components/profile/ProfileEditor';


type Tab = {
    value: string
    icon: ReactElement
    component: ReactNode
    disabled: boolean
}

function getTabs(currentUser: User, user: User): Tab[] {
    const ACCOUNT_TABS = [
        {
            value: 'Personal data',
            icon: <Icon icon={roundAccountBox} width={20} height={20} />,
            component: <ProfileEditor />,
            disabled: false,
        },
        {
            value: 'Change password',
            icon: <Icon icon={roundVpnKey} width={20} height={20} />,
            component: <ChangePassword userId={user?.id} />,
            disabled: currentUser?.id !== user?.id,
        },
    ];

    if (user && user.role === UserRole.TRANSLATOR &&
        (currentUser?.role === UserRole.ADMIN || currentUser?.role === UserRole.TRANSLATOR)) {
        ACCOUNT_TABS.splice(
            2, 0,
            {
                value: 'Holidays',
                icon: <Icon icon={'mdi:island'} width={20} height={20} />,
                component: <MyHolidays />,
                disabled: false,
            },
        );
    }

    return ACCOUNT_TABS;
}

export default getTabs;
