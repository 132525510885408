/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Box, Card, Table, TableBody, TableCell, TableHead,
    TablePagination, TableRow, Typography, useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Moment } from 'moment';
import React, { useState } from 'react';
import Customer from '../../models/Customer';
import Task from '../../models/Task';
import SummaryListRow from './SummaryListRow';

type SummaryListProps = {
    startDate: Moment | null;
    endDate: Moment | null;
    selectedCustomer?: Customer;
    handlePageChange: (params: ParamsType) => void;
    handleRowsChange: (params: ParamsType) => void;
    tasks?: Task[];
    totalCount: number;
    customerId?: string | undefined;
}

type ParamsType = {
    [key: string]: string | number | null | undefined | boolean;
}

const StyledBox = styled(Box)(() => ({
    overflowX: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
}));

export default function SummaryList({
    startDate,
    endDate,
    selectedCustomer,
    handlePageChange,
    handleRowsChange,
    tasks,
    totalCount,
}: SummaryListProps): JSX.Element {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        const offsetResult = newPage * rowsPerPage;
        const params: ParamsType = {};
        params.offset = offsetResult;
        params.limit = limit;

        if (startDate) {
            params.after = startDate.format('YYYY-MM-DD');
        }
        if (endDate) {
            params.before = endDate.format('YYYY-MM-DD');
        }

        if (selectedCustomer) {
            params.customerId = selectedCustomer.id;
        }

        handlePageChange(params);

        setOffset(offsetResult);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const limitResult = parseInt(event.target.value, 10);
        const offsetResult = 0;
        setRowsPerPage(limitResult);
        setPage(0);

        const params: ParamsType = {};
        params.offset = offsetResult;
        params.limit = limitResult;

        if (startDate) {
            params.after = startDate.format('YYYY-MM-DD');
        }
        if (endDate) {
            params.before = endDate.format('YYYY-MM-DD');
        }

        if (selectedCustomer) {
            params.customerId = selectedCustomer.id;
        }

        handleRowsChange(params);

        setOffset(offsetResult);
        setLimit(limitResult);
    };

    return (
        <>
            {tasks && tasks.length > 0 ? (
                <>
                    <Card sx={{ pt: 1 }}>
                        <StyledBox>
                            <Table size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: 20 }} />
                                        <TableCell sx={{ minWidth: 80 }}>
                                            Task ID
                                        </TableCell>
                                        <TableCell sx={{ minWidth: 80 }}>
                                            Created
                                        </TableCell>
                                        <TableCell sx={{ minWidth: 80 }}>
                                            Translation finished
                                        </TableCell>
                                        <TableCell sx={{ minWidth: 80 }}>
                                            Task approved
                                        </TableCell>
                                        <TableCell sx={{ minWidth: 80 }}>Status</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tasks && tasks.map((task) => {
                                        return (
                                            <SummaryListRow
                                                key={task.id}
                                                task={task}
                                                isLight={isLight}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </StyledBox>
                    </Card>
                </>
            ) : tasks && !tasks.length ? (
                <Typography sx={{ pt: 1, pb: 2, mx: 2, textAlign: 'center' }}>
                    <span style={{ fontStyle: 'italic' }}>No tasks to show</span>
                </Typography>
            ) : (
                null
            )}
        </>
    );
}
