/* eslint-disable indent */
import React from 'react';
import useMe from '../../hooks/useMe';
import { UserRole } from '../../enums/UserRole';
import AdminEditor from './EditorByRole/Editor.admin';
import TranslatorEditor from './EditorByRole/Editor.translator';
import AgentEditor from './EditorByRole/Editor.agent';
import TaskLoading from './TaskLoading';


const TaskEditor = () => {
    const { data: me } = useMe();

    switch (me && me.role) {
        case UserRole.ADMIN:
            return <AdminEditor />;
        case UserRole.TRANSLATOR:
            return <TranslatorEditor />;
        case UserRole.USER:
        case UserRole.AGENT:
            return <AgentEditor />;
        default:
            return <TaskLoading />;
    }
};

export default TaskEditor;
