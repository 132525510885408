import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Card, Stack, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import useUserEventsByTask from './useUserEventsByTask';

const Modifications: React.FC<{ taskId?: string }> = ({ taskId }) => {
    const { data: userEvents, isLoading } = useUserEventsByTask(taskId);

    if (!isLoading && !userEvents) {
        return null;
    }


    return <Card>
        <Accordion>
            <AccordionSummary
                expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant='subtitle2' color='text.secondary'>Modifications ({userEvents?.length ?? 0})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {userEvents?.map((userEvent, i) => (
                    <Stack key={i} pb={1}>
                        <Typography variant='caption'>{userEvent.general}</Typography>
                        <Typography ml={1} variant={'caption'} color={'text.secondary'}>{userEvent.details}</Typography>
                    </Stack>
                ))}
            </AccordionDetails>
        </Accordion>
    </Card>;
};

export default Modifications;
