import { useQuery } from 'react-query';
import User from '../../../models/User';
import createQueryString from '../../../utils/createQueryString';
import { UserRole } from '../../../enums/UserRole';
import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import Task from '../../../models/Task';

function useTranslatorOptions(task: Task) {
    const sourceArray = [];
    const targetArray = [];

    if (task.targetLanguages) {
        for (const lang of task.targetLanguages) {
            targetArray.push(lang.id);
        }
    }

    if (task.sourceLanguages) {
        for (const lang of task.sourceLanguages) {
            sourceArray.push(lang.id);
        }
    }

    return useQuery<{ users: User[] }, unknown, { id: string; name: ReactElement }[]>(['users', createQueryString('', {
        sourceLanguages: sourceArray,
        targetLanguages: targetArray,
        role: UserRole.TRANSLATOR,
        style: task.style,
    })], {
        select(data) {
            return data.users.map((user) => ({
                id: user.id ?? 'id missing',
                name: <Typography>
                    {user.firstname}{' '}{user.lastname}{' - '}
                    <Typography variant={'caption'} component={'span'}>{user.canLogin ? 'can Login' : 'cannot Login'}</Typography>
                </Typography>,
            }));
        },
    });
}

export default useTranslatorOptions;
