import CommentType from '../../../models/Comment';
import { Icon } from '@iconify/react';
import moment from 'moment';
import fileIcon from '@iconify/icons-eva/file-text-outline';
import useDownloadFile from '../../../hooks/useDownloadFile';
import { Box, Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { PersonPinCircleTwoTone } from '@mui/icons-material';

const Comment: React.FC<{ comment: CommentType}> = ({ comment }) => {
    const downloadFile = useDownloadFile(`/comments/${comment.id}/download`);
    const date = moment(comment.created).format('MM/DD/YY HH:mm');

    const fullName = comment.user ?`${comment.user?.firstname} ${comment.user?.lastname}` : 'Anonymous';


    return (
        <Stack gap={0.5}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'subtitle2'} color={'primary'} display={'flex'} alignItems={'center'}><PersonPinCircleTwoTone sx={{ mr: 0.5 }}/>{fullName} </Typography>
                {comment.user && <Chip variant={'outlined'} color={'primary'} size={'small'} label={comment.user?.role}/>}
            </Stack>
            {comment.text ? (
                <Typography variant={'body1'} bgcolor={'background.neutral'} borderRadius={1} py={1} px={2} ml={3.5}>
                    {comment.text}
                </Typography>
            ) : (
                <Box bgcolor={'background.neutral'} borderRadius={1} py={1} px={2} ml={3.5}>
                    <Tooltip title="Download File">
                        <IconButton onClick={downloadFile}>
                            <Icon color="inherit" icon={fileIcon} />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
            <Typography align={'right'} variant={'caption'} color={'text.secondary'}>{ date }</Typography>
        </Stack>
    );
};

export default Comment;
