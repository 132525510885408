import { Container, Grid, Typography } from '@mui/material';
import Page from '../../../components/Page';
// import TopLanguagePairs from './widgets/TopLanguagePairs';
import KeyFigures from './KeyFigures';
import useMe from '../../../hooks/useMe';
import TasksCreated from './widgets/TasksCreated';

const Dashboard: React.FC = () => {
    const { data: me } = useMe();
    return (
        <Page title="Dashboard | YLT">
            <Container maxWidth={'lg'}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={'h5'} >Hello {me?.firstname ?? 'user'}, welcome back!</Typography>
                    </Grid>
                    <Grid container item spacing={2} xs={12}>
                        <KeyFigures />
                    </Grid>
                    <Grid item xs={12}>
                        <TasksCreated />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

export default Dashboard;
