import React from 'react';
import { Stack, Typography } from '@mui/material';
import Task from '../models/Task';
import { TaskStatus } from '../enums/TaskStatus';
import moment from 'moment';

type DeadlineProps = {
    task: Task;
};

export default function Deadline({ task }: DeadlineProps): JSX.Element {
    if (task.status === TaskStatus.INVALID_FILE) {
        return (
            <Typography variant='caption'>Cannot be assigned</Typography>
        );
    }

    if (task.status === TaskStatus.READY_FOR_DOWNLOAD) {
        return (
            <Typography variant='caption'>Done</Typography>
        );
    }


    return (
        <Stack direction='row' sx={{ alignItems: 'center' }}>
            <Typography variant='caption'>{task.deadLine ? moment(task.deadLine)?.format('MM/DD/YY') : 'n/a'}</Typography>
        </Stack>
    );
}
